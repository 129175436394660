/* BOOTSTRAP */
.container {
    margin-left: auto;
    margin-right: auto
  }
  
  @media(min-width: 768px) {
    .container {
      width: 750px
    }
  }
  
  @media(min-width: 992px) {
    .container {
      width: 970px
    }
  }
  
  @media(min-width: 1200px) {
    .container {
      width: 1170px
    }
  }
  
  @media(min-width: 1400px) {
    .container {
      width: 1380px
    }
  }
  
  @media(min-width: 1600px) {
    .container {
      width: 1580px
    }
  }
  
  .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-55, .col-md-6, .col-md-65, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-55, .col-sm-6, .col-sm-65, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px
  }
  
  .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    float: left
  }
  
  .col-xs-12 {
    width: 100%
  }
  
  .col-xs-11 {
    width: 91.66666667%
  }
  
  .col-xs-10 {
    width: 83.33333333%
  }
  
  .col-xs-9 {
    width: 75%
  }
  
  .col-xs-8 {
    width: 66.66666667%
  }
  
  .col-xs-7 {
    width: 58.33333333%
  }
  
  .col-xs-6 {
    width: 50%
  }
  
  .col-xs-5 {
    width: 41.66666667%
  }
  
  .col-xs-4 {
    width: 33.33333333%
  }
  
  .col-xs-3 {
    width: 25%
  }
  
  .col-xs-2 {
    width: 16.66666667%
  }
  
  .col-xs-1 {
    width: 8.33333333%
  }
  
  .col-xs-offset-12 {
    margin-left: 100%
  }
  
  .col-xs-offset-11 {
    margin-left: 91.66666667%
  }
  
  .col-xs-offset-10 {
    margin-left: 83.33333333%
  }
  
  .col-xs-offset-9 {
    margin-left: 75%
  }
  
  .col-xs-offset-8 {
    margin-left: 66.66666667%
  }
  
  .col-xs-offset-7 {
    margin-left: 58.33333333%
  }
  
  .col-xs-offset-6 {
    margin-left: 50%
  }
  
  .col-xs-offset-5 {
    margin-left: 41.66666667%
  }
  
  .col-xs-offset-4 {
    margin-left: 33.33333333%
  }
  
  .col-xs-offset-3 {
    margin-left: 25%
  }
  
  .col-xs-offset-2 {
    margin-left: 16.66666667%
  }
  
  .col-xs-offset-1 {
    margin-left: 8.33333333%
  }
  
  .col-xs-offset-0 {
    margin-left: 0
  }
  
  @media (min-width: 768px) {
    .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-55, .col-sm-6, .col-sm-65, .col-sm-7, .col-sm-8, .col-sm-9 {
      float: left
    }
    .col-sm-12 {
      width: 100%
    }
    .col-sm-11 {
      width: 91.66666667%
    }
    .col-sm-10 {
      width: 83.33333333%
    }
    .col-sm-9 {
      width: 75%
    }
    .col-sm-8 {
      width: 66.66666667%
    }
    .col-sm-7 {
      width: 58.33333333%
    }
    .col-sm-65 {
      width: 55%
    }
    .col-sm-6 {
      width: 50%
    }
    .col-sm-55 {
      width: 45%
    }
    .col-sm-5 {
      width: 41.66666667%
    }
    .col-sm-4 {
      width: 33.33333333%
    }
    .col-sm-3 {
      width: 25%
    }
    .col-sm-2 {
      width: 16.66666667%
    }
    .col-sm-1 {
      width: 8.33333333%
    }
    .col-sm-offset-12 {
      margin-left: 100%
    }
    .col-sm-offset-11 {
      margin-left: 91.66666667%
    }
    .col-sm-offset-10 {
      margin-left: 83.33333333%
    }
    .col-sm-offset-9 {
      margin-left: 75%
    }
    .col-sm-offset-8 {
      margin-left: 66.66666667%
    }
    .col-sm-offset-7 {
      margin-left: 58.33333333%
    }
    .col-sm-offset-6 {
      margin-left: 50%
    }
    .col-sm-offset-5 {
      margin-left: 41.66666667%
    }
    .col-sm-offset-4 {
      margin-left: 33.33333333%
    }
    .col-sm-offset-3 {
      margin-left: 25%
    }
    .col-sm-offset-2 {
      margin-left: 16.66666667%
    }
    .col-sm-offset-1 {
      margin-left: 8.33333333%
    }
    .col-sm-offset-0 {
      margin-left: 0
    }
  }
  
  @media (min-width: 992px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-55, .col-md-6, .col-md-65, .col-md-7, .col-md-8, .col-md-9 {
      float: left
    }
    .col-md-12 {
      width: 100%
    }
    .col-md-11 {
      width: 91.66666667%
    }
    .col-md-10 {
      width: 83.33333333%
    }
    .col-md-9 {
      width: 75%
    }
    .col-md-8 {
      width: 66.66666667%
    }
    .col-md-7 {
      width: 58.33333333%
    }
    .col-md-65 {
      width: 55%
    }
    .col-md-55 {
      width: 45%
    }
    .col-md-6 {
      width: 50%
    }
    .col-md-5 {
      width: 41.66666667%
    }
    .col-md-4 {
      width: 33.33333333%
    }
    .col-md-3 {
      width: 25%
    }
    .col-md-2 {
      width: 16.66666667%
    }
    .col-md-1 {
      width: 8.33333333%
    }
    .col-md-offset-12 {
      margin-left: 100%
    }
    .col-md-offset-11 {
      margin-left: 91.66666667%
    }
    .col-md-offset-10 {
      margin-left: 83.33333333%
    }
    .col-md-offset-9 {
      margin-left: 75%
    }
    .col-md-offset-8 {
      margin-left: 66.66666667%
    }
    .col-md-offset-7 {
      margin-left: 58.33333333%
    }
    .col-md-offset-6 {
      margin-left: 50%
    }
    .col-md-offset-5 {
      margin-left: 41.66666667%
    }
    .col-md-offset-4 {
      margin-left: 33.33333333%
    }
    .col-md-offset-3 {
      margin-left: 25%
    }
    .col-md-offset-2 {
      margin-left: 16.66666667%
    }
    .col-md-offset-1 {
      margin-left: 8.33333333%
    }
  }
  
  .container:after, .container:before, .row:after, .row:before {
    content: " ";
    display: table
  }
  
  .container:after, .row:after {
    clear: both
  }
  
  @-ms-viewport {
    width: device-width
  }
  
  .visible-md.visible-sm, .visible-xs {
    display: none !important
  }
  
  @media (max-width: 767px) {
    .container {
      padding: 0 30px
    }
    .visible-xs {
      display: block !important
    }
    table.visible-xs {
      display: table !important
    }
    tr.visible-xs {
      display: table-row !important
    }
    td.visible-xs, th.visible-xs {
      display: table-cell !important
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
      display: block !important
    }
    table.visible-sm {
      display: table !important
    }
    tr.visible-sm {
      display: table-row !important
    }
    td.visible-sm, th.visible-sm {
      display: table-cell !important
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
      display: block !important
    }
    table.visible-md {
      display: table !important
    }
    tr.visible-md {
      display: table-row !important
    }
    td.visible-md, th.visible-md {
      display: table-cell !important
    }
  }
  
  @media (max-width: 767px) {
    .hidden-xs {
      display: none !important
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
      display: none !important
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
      display: none !important
    }
  }