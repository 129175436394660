.auth-options {
  .options-container {
    padding: 28px 15px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    max-width: 470px;
    margin: 0 auto;
    text-align: center;
    background: #F2F2F3;

    .options-title {
      font-size: 22px;
      font-weight: 500;
      max-width: 470px;
      margin: 0 auto 23px;
      position: relative;
      color: rgba(0, 0, 0, 0.65);
      display: inline-block;

      &:before {
        content: '';
        position: absolute;
        bottom: -4px;
        right: 50%;
        margin-right: -69px;
        width: 139px;
        height: 2px;
        background: #fed94d;
      }
    }

    .options-list {
      font-size: 17px;
      font-weight: 500;
      margin: 0 10px;
      display: inline-block;
      color: rgba(0, 0, 0, 0.65);
    }

    .options-external {
      margin: 0 10px;
      cursor: pointer;

      &.fb {
        max-width: 41px;
      }

      &.google {
        max-width: 38px;
      }
    }
  }
}
